<script>
import Layout from '@layouts/main';
import { isBrazil } from '@utils/helper-region';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';
import map from 'lodash/map';
import { enumStatus, enumOBDProvider, enumFleetFlags } from '@utils/car';
import CarCategory from '@components/select/car-category.vue';
import FleetTagsEdit from '@components/fleet/fleet-tags-edit.vue';
import FleetStatusGroupAcl from '@components/fleet/fleet-status-group-acl.vue';
import { makeQuery } from '@graphql/middleware'

import CAR_GET from '@graphql/car/queries/get.gql';
import CAR_UPDATE from '@graphql/car/mutations/update.gql';
import CAR_DELETE from '@graphql/car/mutations/delete.gql';
import SYNC_DENATRAN from '@graphql/car/mutations/syncDenatran.gql';
import CAR_OPTIONS from '@graphql/car/queries/options.gql';

import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  name: 'FleetProfileEdit',
  page: {
    title: 'Frota',
  },
  components: {
    Layout,
    ContentLoading,
    FleetHeader,
    CarCategory,
    FleetStatusGroupAcl,
    FleetTagsEdit,
  },
  data: () => ({
    data: {
      id: null,
      fuel: [],
      license_issued_at: null,
      place: null,
      original_odometer: 0,
      obd_provider: [],
      mobilized_at: null,
      demobilized_at: null,
      billed_at: null,
      product: 'RENTAL',
      status: null,
      readonly: isBrazil(),
      canUpdateStatus: false,
      category: '',
      fleetTags: [],
    },
    colorOptions: [
      { value: 'YELLOW', text: 'Yellow' },
      { value: 'BLUE', text: 'Blue' },
      { value: 'BEIGE', text: 'Beige' },
      { value: 'WHITE', text: 'White' },
      { value: 'BRONZE', text: 'Bronze' },
      { value: 'GREY', text: 'Grey' },
      { value: 'GOLDEN', text: 'Golden' },
      { value: 'ORANGE', text: 'Orange' },
      { value: 'BROWN', text: 'Brown' },
      { value: 'SILVER', text: 'Silver' },
      { value: 'BLACK', text: 'Black' },
      { value: 'PINK', text: 'Pink' },
      { value: 'PURPLE', text: 'Purple' },
      { value: 'GREEN', text: 'Green' },
      { value: 'RED', text: 'Red' },
      { value: 'WINE', text: 'Wine' },
      { value: 'OTHERS', text: 'Others' },
    ],
    car: {
      model: {
        id: null,
        name: '',
      },
      brand: {
        id: null,
        name: '',
      },
      color: {
        id: null,
        name: '',
      },
    },
    car_options: {
      fuel: [],
      places: {
        items: [],
      },
    },
    form_status_options: enumStatus,
    form_fleet_flags_options: [...enumOBDProvider, ...enumFleetFlags],
    block_ui: false,
    is_loading: true,
  }),
  computed: {
    ...mapGetters('user', ['findGroups']),
    ...mapGetters('tags', ['is_load_fleet_tags']),

    carBrandName: {
      get() {
        return this.car.brand ? this.car.brand.name : '';
      },
      set(value) {
        if (this.car.brand) {
          this.car.brand.name = value;
        } else {
          this.car.brand = {
            name: value,
          };
        }
      },
    },
    carModelName: {
      get() {
        return this.car.model ? this.car.model.name : '';
      },
      set(value) {
        if (this.car.model) {
          this.car.model.name = value;
        } else {
          this.car.model = {
            name: value,
          };
        }
      },
    },
    carColorId: {
      get() {
        return this.car.color ? this.car.color.id : '';
      },
      set(value) {
        if (this.car.color) {
          this.car.color.id = value;
        } else {
          this.car.color = {
            id: value,
          };
        }
      },
    },
    noMexico () {
      return isBrazil()
    },
    canDeleteCar(){
      return this.car?.status === 'PRE_OPERATION' && this.findGroups('ACL:FLEET:REMOVE')
    },
    canEditCategory() {
      return this.findGroups('ACL:FLEET:EDIT_CATEGORY') && !this.block_ui;
    },
  },
  mounted() {
    this.canUpdateStatus();
    this.getCar();
  },
  apollo: {
    car_options: {
      query() {
        return CAR_OPTIONS;
      },
      update: data => data,
    },
  },
  methods: {
    ...mapMutations('tickets', ['clearVehicleMonitored']),
    ...mapActions('fleet', ['clearHeader']),
    setFleetTags( fleetTags ) {
      this.data.obd_provider = fleetTags;
    },
    setFleetStatus( fleetStatus ) {
      this.data.status = fleetStatus;
    },
    setTags(tags) {
      this.data.obd_provider = [...tags]
    },
    getCar () {
      makeQuery(
        CAR_GET,
        {
          id: this.$route.params.id,
        }
      )
      .then((res) => {
        if( !res.data ) {
          return;
        }
        this.car = res.data.car;
        this.data = res.data.car;
        this.data.fuel = map(res.data.car.fuel, 'id');
        this.data.place = res.data.car.place?.id;
        this.data.original_odometer = parseInt(res.data.car.original_odometer, 10);
        this.data.obd_provider = res.data.car.obd_provider && typeof res.data.car.obd_provider === 'string' ? res.data.car.obd_provider.split(';') : [];

        this.changeCarCategory(res.data.car.category ? res.data.car.category : null);

      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.is_loading = false;
      });
    },
    getStatus() {
      return !(this.data.status === 'RETIRED_RETURN'
        || this.data.status === 'RETIRED_THEFT'
        || this.data.status === 'RETIRED_CRASH');
    },
    canUpdateStatus(){
      if ( !this.$store.getters['user/groups'].includes('ACL:FLEET:CHANGE_STATUS')) {
        this.data.canUpdateStatus = true;
      }
    },
    updateProfileCheckedAt() {
      this.block_ui = true;

      this.$apollo
        .mutate({
          mutation: SYNC_DENATRAN,
          variables: {
            id: this.data.id
          },
        })
        .then(() => {
          this.$swal({
            type: 'success',
            title: 'Alteração realizada',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            if (this.$route.query.redir) {
              this.$router.push({
                path: this.$route.query.redir,
              });
            } else {
              this.$router.push({
                path: '/fleet/' + this.data.id,
              });
            }
          });
        })
        .catch(() => {
          this.$swal({
            type: 'error',
            title: 'Ocorreu um erro ao salvar',
            showConfirmButton: false,
            timer: 2500,
          }).then(() => {
            this.block_ui = false;
            this.data = original_data;
          });
        });
    },
    updateCar() {
      if (!this.getStatus() && !Date.parse(this.data.demobilized_at)) {
        this.$swal({
          type: 'error',
          title: 'Preencha a data de desmobilização do carro',
          showConfirmButton: false,
          timer: 3000,
        });
        return;
      }

      const original_data = this.data;
      this.block_ui = true;

      const obd_provider = [...this.data.obd_provider]
        .filter((item, index, self) => self.indexOf(item) === index)
        .join(';');

      this.$apollo
        .mutate({
          mutation: CAR_UPDATE,
          variables: {
            id: this.data.id,
            original_odometer: parseInt(this.data.original_odometer),
            place: this.data.place,
            obd_provider,
            mobilized_at: this.data.mobilized_at,
            demobilized_at: this.data.demobilized_at,
            billed_at: this.data.billed_at,
            status: this.data.status,
            license_plate: this.car.license_plate,
            license_number: this.car.license_number,
            vin: this.car.vin,
            brand: this.car.brand.name.toUpperCase(),
            model: this.car.model.name.toUpperCase(),
            model_year: parseInt(this.car.model_year, 10),
            assemble_year: parseInt(this.car.assemble_year, 10),
            license_issued_at: this.data.license_issued_at,
            color: this.car.color.id,
            city: this.car.city,
            state: this.car.state,
            fuel: this.data.fuel,
            owner_name: this.car.owner_name,
            product: this.car.product,
            category: this.data.category,
            updated_at: this.data.updated_at
          },
        })
        .then(() => {
          this.$swal({
            type: 'success',
            title: 'Alteração realizada',
            showConfirmButton: false,
            timer: 2500,
          }).then(async () => {
            this.clearVehicleMonitored();
            await this.clearHeader();
            if (this.$route.query.redir) {
              await this.$router.push({
                path: this.$route.query.redir,
              });
            } else {
              await this.$router.push({
                path: '/fleet/' + this.data.id,
              });
            }
          });
        })
        .catch((err) => {
          const sessionError = err.message === 'GraphQL error: Car already updated in other session, please update your page and try it again!';
          this.$swal({
            type: 'error',
            title: sessionError ? 'Carro já atualizado por outro usuário' : 'Ocorreu um erro ao salvar',
            text: sessionError && 'Atualize sua página e tente novamente!',
            showConfirmButton: sessionError,
            timer: sessionError ? 0 : 2500,
          }).then(() => {
            this.block_ui = false;
            this.data = original_data;
          });
        });
    },
    goBack() {
      if (this.$route.query.redir) {
        this.$router.push({
          path: this.$route.query.redir,
        });
      } else {
        this.$router.push({
          path: '/fleet/' + this.data.id,
        });
      }
    },
    changeCarCategory (value) {
      this.data.category = value
    },
    async carDelete () {
      return await this.$apollo
        .mutate({
          mutation: CAR_DELETE,
          variables: {
            id: this.data.id,
          },
        })
    },
    async confirmDeletion() {
      const {license_plate} = this.data;
      const configSwal = {
        type: 'warning',
        title: this.$t('fleetProfile.modals.removeFleet.title'),
        text: this.$t(`fleetProfile.modals.removeFleet.text`, { license_plate }),
        showConfirmButton: true,
        confirmButtonText: this.$t(`fleetProfile.modals.removeFleet.buttons.confirm`),
        confirmButtonClass: 'driver-container-link-car-button',
        showCancelButton: true,
        cancelButtonText: this.$t(`fleetProfile.modals.removeFleet.buttons.cancel`),
        showLoaderOnConfirm: true,
        preConfirm: async () => await this.carDelete(),
      };
      this.$swal(configSwal)
        .then(data => {
          if (data.dismiss) return;

          const configSwalSuccess = {
            type: 'success',
            title: this.$t(`fleetProfile.modals.removeFleet.results.success.text`, { license_plate }),
            text: '',
          };

          this.$swal(configSwalSuccess);
          this.$router.push({ path: '/fleet/' });
        })
        .catch(err => {
          const configSwalFail = {
            type: 'error',
            title: this.$t(`fleetProfile.modals.removeFleet.results.error.text`),
            text: err,
          };

          this.$swal(configSwalFail);
        });
      }
  },
};
</script>

<template>
  <Layout>
    <fleet-header :id="$route.params.id" />
    <div v-show="!is_loading && !is_load_fleet_tags" class="animated fadeIn">
      <b-alert
        variant="info"
        class="info"
        :show="!car.license_number"
      >
        Aguardando sincronização deste veículo com o DENATRAN.
      </b-alert>
      <b-card>
        <b-row slot="header">
          <b-col cols="6">
            <h4 class="mt-2">
              {{ `${$t('fleet.texts.edit')} ${$t('fleet.texts.register')}` }}
            </h4>
          </b-col>
          <b-col cols="6" class="text-right mt-1">
            <b-button
              v-if="noMexico"
              :disabled="!car.profile_checked_at"
              class="mr-2"
              variant="primary"
              @click="updateProfileCheckedAt"
            >
              {{ $t('fleet.buttons.syncDenatran') }}
            </b-button>
            <b-button
              :disabled="block_ui"
              class="mr-2"
              variant="success"
              @click="updateCar"
            >
              {{ $t('fleet.buttons.save') }}
            </b-button>
            <b-button
              v-if="canDeleteCar"
              variant="danger"
              class="mr-2"
              @click="confirmDeletion()"
            >
              {{ $t('fleetProfile.buttons.remove') }}
            </b-button>
            <b-button
              :disabled="block_ui"
              variant="danger"
              @click="goBack"
            >
              {{ $t('fleet.buttons.cancel') }}
            </b-button>
          </b-col>
        </b-row>
        <b-form>
          <b-form-row>
            <b-form-group :label="$t('fleet.texts.plate')" label-for="fleet-license-plate" class="col-md-2 col-sm-12">
              <b-form-input
                id="fleet-license-plate"
                v-model.trim="car.license_plate"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group :label="$t('fleet.texts.renavam')" label-for="fleet-renavam" class="col-md-3 col-sm-12">
              <b-form-input
                id="fleet-renavam"
                v-model.trim="car.license_number"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group label="VIN (Chassi)" label-for="fleet-vin" class="col-md-4 col-sm-12">
              <b-form-input
                id="fleet-vin"
                v-model.trim="car.vin"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group
              v-if="data.status"
              id="fg-fleet-status"
              label="Status"
              label-for="fleet-status"
              class="col-md-3 col-sm-12"
            >
              <fleet-status-group-acl
                :model="data.status"
                :disabled="data.canUpdateStatus"
                :options="form_status_options"
                @setFleetStatus="setFleetStatus"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row v-if="car.license_number">
            <b-form-group :label="$t('fleet.texts.brand')" label-for="fleet-brand" class="col-md-2 col-sm-12">
              <b-form-input
                id="fleet-brand"
                v-model.trim="carBrandName"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group
              id="fg-fleet-model"
              :label="$t('fleet.texts.model')"
              label-for="fleet-model"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-model"
                v-model.trim="carModelName"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group
              :label="$t('fleet.texts.modelyear')"
              label-for="fleet-model-year"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-model-year"
                v-model.trim="car.model_year"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group
              :label="$t('fleet.texts.manufactureyear')"
              label-for="fleet-assemble-year"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-assemble-year"
                v-model.trim="car.assemble_year"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group
              :label="$t('fleet.texts.licensedate')"
              label-for="fleet-license-issued"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-license-issued"
                v-model.trim="data.license_issued_at"
                :readonly="data.readonly"
                type="date"
              />
            </b-form-group>
            <b-form-group
              :label="$t('fleet.texts.predominantcolor')"
              label-for="fleet-color"
              class="col-md-2 col-sm-12"
            >
              <b-form-select
                id="fleet-color"
                v-model="carColorId"
                :disabled="data.readonly"
                :options="colorOptions"
                type="text"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group
              :label="$t('fleet.texts.ownername')"
              label-for="fleet-owner-name"
              class="col-md-6 col-sm-12"
            >
              <b-form-input
                id="fleet-owner-name"
                v-model.trim="car.owner_name"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group :label="$t('fleet.texts.city')" label-for="fleet-city" class="col-md-5 col-sm-12">
              <b-form-input
                id="fleet-city"
                v-model.trim="car.city"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
            <b-form-group :label="$t('fleet.texts.state')" label-for="fleet-state" class="col-md-1 col-sm-12">
              <b-form-input
                id="fleet-state"
                v-model.trim="car.state"
                :readonly="data.readonly"
                type="text"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <b-form-group :label="$t('fleet.texts.fuel')" label-for="fleet-fuel" class="col-md-6 col-sm-12">
              <b-form-checkbox-group
                id="fleet-fuel"
                v-model="data.fuel"
                :disabled="data.readonly"
                class="mt-2"
                :options="car_options.fuels"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
            <b-form-group
              :label="$t('fleet.texts.mobilizationdate')"
              label-for="fleet-mobilized-at"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-mobilized-at"
                v-model="data.mobilized_at"
                :disabled="this.block_ui"
                type="date"
              />
            </b-form-group>

            <b-form-group
              :label="$t('fleet.texts.paymentstartdate')"
              label-for="fleet-billed-at"
              class="col-md-2 col-sm-12"
            >

              <b-form-input id="fleet-billed-at" v-model="data.billed_at" type="date" />
            </b-form-group>

            <b-form-group
              :label="$t('fleet.texts.demobilizationdate')"
              label-for="fleet-mobilized-at"
              class="col-md-2 col-sm-12"
            >
              <b-form-input
                id="fleet-demobilized-at"
                v-model="data.demobilized_at"
                :disabled="getStatus() || !!data.demobilized_at"
                type="date"
              />
            </b-form-group>

            <b-form-group :label="$t('fleet.texts.courtyard')" label-for="fleet-place" class="col-md-4 col-sm-12">
              <b-form-select
                id="fleet-place"
                v-model="data.place"
                :disabled="block_ui"
                :options="car_options.places.items"
                value-field="id"
                text-field="name"
              />
            </b-form-group>
          </b-form-row>
          <b-form-row>
            <car-category
              :key="data.category"
              category-id="fleet-category"
              :value="data.category"
              :disabled="!canEditCategory"
              @category-value="changeCarCategory"
            />
            <b-form-group
              :label="$t('fleet.texts.fleettags')"
              label-for="fleet-obd-provider"
              class="col-md-12 col-sm-12"
            >
              <fleet-tags-edit :car-id="$route.params.id" @setTags="setTags" />
            </b-form-group>
          </b-form-row>
        </b-form>
        <b-row slot="footer">
          <b-col class="text-right">
            <b-button :disabled="block_ui" class="mr-2" variant="success"
                      @click="updateCar"
            >
              Salvar
            </b-button>
            <b-button
              :disabled="block_ui"
              :to="'/fleet/' + $route.params.id"
              variant="danger"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </div>
    <div v-show="is_loading || is_load_fleet_tags" class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
.license-plate {
  text-transform: uppercase;
}

.info {
  border-color: #8fd9ef;
}

.streetview {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}
</style>
