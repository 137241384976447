var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c("fleet-header", { attrs: { id: _vm.$route.params.id } }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.is_loading && !_vm.is_load_fleet_tags,
              expression: "!is_loading && !is_load_fleet_tags",
            },
          ],
          staticClass: "animated fadeIn",
        },
        [
          _c(
            "b-alert",
            {
              staticClass: "info",
              attrs: { variant: "info", show: !_vm.car.license_number },
            },
            [_vm._v(" Aguardando sincronização deste veículo com o DENATRAN. ")]
          ),
          _c(
            "b-card",
            [
              _c(
                "b-row",
                { attrs: { slot: "header" }, slot: "header" },
                [
                  _c("b-col", { attrs: { cols: "6" } }, [
                    _c("h4", { staticClass: "mt-2" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            `${_vm.$t("fleet.texts.edit")} ${_vm.$t(
                              "fleet.texts.register"
                            )}`
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-right mt-1", attrs: { cols: "6" } },
                    [
                      _vm.noMexico
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: {
                                disabled: !_vm.car.profile_checked_at,
                                variant: "primary",
                              },
                              on: { click: _vm.updateProfileCheckedAt },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t("fleet.buttons.syncDenatran")) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: { disabled: _vm.block_ui, variant: "success" },
                          on: { click: _vm.updateCar },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("fleet.buttons.save")) + " "
                          ),
                        ]
                      ),
                      _vm.canDeleteCar
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-2",
                              attrs: { variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.confirmDeletion()
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("fleetProfile.buttons.remove")
                                  ) +
                                  " "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "b-button",
                        {
                          attrs: { disabled: _vm.block_ui, variant: "danger" },
                          on: { click: _vm.goBack },
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("fleet.buttons.cancel")) + " "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-form",
                [
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-2 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.plate"),
                            "label-for": "fleet-license-plate",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-license-plate",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.license_plate,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "license_plate",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.license_plate",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-3 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.renavam"),
                            "label-for": "fleet-renavam",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-renavam",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.license_number,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "license_number",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.license_number",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-4 col-sm-12",
                          attrs: {
                            label: "VIN (Chassi)",
                            "label-for": "fleet-vin",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-vin",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.vin,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "vin",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.vin",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm.data.status
                        ? _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-3 col-sm-12",
                              attrs: {
                                id: "fg-fleet-status",
                                label: "Status",
                                "label-for": "fleet-status",
                              },
                            },
                            [
                              _c("fleet-status-group-acl", {
                                attrs: {
                                  model: _vm.data.status,
                                  disabled: _vm.data.canUpdateStatus,
                                  options: _vm.form_status_options,
                                },
                                on: { setFleetStatus: _vm.setFleetStatus },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.car.license_number
                    ? _c(
                        "b-form-row",
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.brand"),
                                "label-for": "fleet-brand",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-brand",
                                  readonly: _vm.data.readonly,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.carBrandName,
                                  callback: function ($$v) {
                                    _vm.carBrandName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "carBrandName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                id: "fg-fleet-model",
                                label: _vm.$t("fleet.texts.model"),
                                "label-for": "fleet-model",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-model",
                                  readonly: _vm.data.readonly,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.carModelName,
                                  callback: function ($$v) {
                                    _vm.carModelName =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "carModelName",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.modelyear"),
                                "label-for": "fleet-model-year",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-model-year",
                                  readonly: _vm.data.readonly,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.car.model_year,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.car,
                                      "model_year",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "car.model_year",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.manufactureyear"),
                                "label-for": "fleet-assemble-year",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-assemble-year",
                                  readonly: _vm.data.readonly,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.car.assemble_year,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.car,
                                      "assemble_year",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "car.assemble_year",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.licensedate"),
                                "label-for": "fleet-license-issued",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "fleet-license-issued",
                                  readonly: _vm.data.readonly,
                                  type: "date",
                                },
                                model: {
                                  value: _vm.data.license_issued_at,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.data,
                                      "license_issued_at",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "data.license_issued_at",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "col-md-2 col-sm-12",
                              attrs: {
                                label: _vm.$t("fleet.texts.predominantcolor"),
                                "label-for": "fleet-color",
                              },
                            },
                            [
                              _c("b-form-select", {
                                attrs: {
                                  id: "fleet-color",
                                  disabled: _vm.data.readonly,
                                  options: _vm.colorOptions,
                                  type: "text",
                                },
                                model: {
                                  value: _vm.carColorId,
                                  callback: function ($$v) {
                                    _vm.carColorId = $$v
                                  },
                                  expression: "carColorId",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.ownername"),
                            "label-for": "fleet-owner-name",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-owner-name",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.owner_name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "owner_name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.owner_name",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-5 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.city"),
                            "label-for": "fleet-city",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-city",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.city,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "city",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.city",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-1 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.state"),
                            "label-for": "fleet-state",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-state",
                              readonly: _vm.data.readonly,
                              type: "text",
                            },
                            model: {
                              value: _vm.car.state,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.car,
                                  "state",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "car.state",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-row",
                    [
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-6 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.fuel"),
                            "label-for": "fleet-fuel",
                          },
                        },
                        [
                          _c("b-form-checkbox-group", {
                            staticClass: "mt-2",
                            attrs: {
                              id: "fleet-fuel",
                              disabled: _vm.data.readonly,
                              options: _vm.car_options.fuels,
                              "value-field": "id",
                              "text-field": "name",
                            },
                            model: {
                              value: _vm.data.fuel,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "fuel", $$v)
                              },
                              expression: "data.fuel",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-2 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.mobilizationdate"),
                            "label-for": "fleet-mobilized-at",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-mobilized-at",
                              disabled: this.block_ui,
                              type: "date",
                            },
                            model: {
                              value: _vm.data.mobilized_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "mobilized_at", $$v)
                              },
                              expression: "data.mobilized_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-2 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.paymentstartdate"),
                            "label-for": "fleet-billed-at",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: "fleet-billed-at", type: "date" },
                            model: {
                              value: _vm.data.billed_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "billed_at", $$v)
                              },
                              expression: "data.billed_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-2 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.demobilizationdate"),
                            "label-for": "fleet-mobilized-at",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "fleet-demobilized-at",
                              disabled:
                                _vm.getStatus() || !!_vm.data.demobilized_at,
                              type: "date",
                            },
                            model: {
                              value: _vm.data.demobilized_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "demobilized_at", $$v)
                              },
                              expression: "data.demobilized_at",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-4 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.courtyard"),
                            "label-for": "fleet-place",
                          },
                        },
                        [
                          _c("b-form-select", {
                            attrs: {
                              id: "fleet-place",
                              disabled: _vm.block_ui,
                              options: _vm.car_options.places.items,
                              "value-field": "id",
                              "text-field": "name",
                            },
                            model: {
                              value: _vm.data.place,
                              callback: function ($$v) {
                                _vm.$set(_vm.data, "place", $$v)
                              },
                              expression: "data.place",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-row",
                    [
                      _c("car-category", {
                        key: _vm.data.category,
                        attrs: {
                          "category-id": "fleet-category",
                          value: _vm.data.category,
                          disabled: !_vm.canEditCategory,
                        },
                        on: { "category-value": _vm.changeCarCategory },
                      }),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "col-md-12 col-sm-12",
                          attrs: {
                            label: _vm.$t("fleet.texts.fleettags"),
                            "label-for": "fleet-obd-provider",
                          },
                        },
                        [
                          _c("fleet-tags-edit", {
                            attrs: { "car-id": _vm.$route.params.id },
                            on: { setTags: _vm.setTags },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "b-col",
                    { staticClass: "text-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-2",
                          attrs: { disabled: _vm.block_ui, variant: "success" },
                          on: { click: _vm.updateCar },
                        },
                        [_vm._v(" Salvar ")]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.block_ui,
                            to: "/fleet/" + _vm.$route.params.id,
                            variant: "danger",
                          },
                        },
                        [_vm._v(" Cancelar ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.is_loading || _vm.is_load_fleet_tags,
              expression: "is_loading || is_load_fleet_tags",
            },
          ],
          staticClass: "animated fadeIn",
        },
        [_c("content-loading")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }